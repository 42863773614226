import { Fragment } from "react";
import { MetroSpinner } from "react-spinners-kit";

const PageLoad = () => {
  return (
    <Fragment>
      <div className={"w-screen h-screen flex justify-center items-center"}>
        <MetroSpinner color={"#8B0000"} loading={true} size={10} />
      </div>
    </Fragment>
  );
};

export default PageLoad;
