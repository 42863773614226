import { Fragment } from "react";
import { ImpulseSpinner } from "react-spinners-kit";

const ViewLoad = () => {
  return (
    <Fragment>
      <div className={"w-full h-full flex justify-center items-center"}>
        <ImpulseSpinner
          frontColor={"#FFC100"}
          backColor={"#D5A021"}
          loading={true}
          size={40}
        />
      </div>
    </Fragment>
  );
};

export default ViewLoad;
