import { Fragment } from "react";
import AppNavigator from "./services/context";
import { Toaster } from "react-hot-toast";

const App = () => {
  return (
    <Fragment>
      <AppNavigator />
      <Toaster
        position={"bottom-center"}
        reverseOrder={false}
        toastOptions={{
          // Define default options

          className: "",
          style: {
            margin: "40px",
            background: "#363636",
            // background: "#00e676",
            color: "#fff",
            zIndex: 1,
            // width: "28vh",
          },
          duration: 5000,
          // Default options for specific types
          success: {
            duration: 8000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
          error: {
            style: {
              background: "#ffa726",
            },
            duration: 8000,
          },
        }}
      />
    </Fragment>
  );
};

export default App;
