import { Fragment, lazy, Suspense } from "react";
import { PageLoader } from "../../component/loaders";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import HandleNotFound from "../../navigation/handle-not-found";

const LoginComponent = lazy(() => import("../../pages/login"));
const LayoutComponent = lazy(() => import("../../shared/layout"));
const ForgotPassword = lazy(() => import("../../pages/login/forgotpassword"));
const VerifyPassword = lazy(() => import("../../pages/login/verifyPassword"));
const UpdatePassword = lazy(() => import("../../pages/login/updatePassword"));

export const SettingsConfig = () => {
  return (
    <Fragment>
      <BrowserRouter>
        <Suspense fallback={PageLoader()}>
          <Switch>
            <Route component={LoginComponent} path={"/login"} exact={true} />
            <Route component={ForgotPassword} path={"/forgotpassword"} exact={true} />
            <Route component={VerifyPassword} path={"/verifypassword"} exact={true}/>
            <Route component={UpdatePassword} path={"/updatepassword"} exact={true}/>
            
            {/* handle 404s */}
            <Route
              exact={true}
              component={HandleNotFound}
              path={"/not-found"}
            />
            <Route component={LayoutComponent} path={"/"} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </Fragment>
  );
};
