import Cookies from "js-cookie";

export const BASE_URL = process.env.NODE_ENV === "development"? process.env.REACT_APP_DEVELOPMENT_SERVER_URL : process.env.REACT_APP_PRODUCTION_SERVER_URL;
class Auth {
  getCipher() {
    return Cookies.get("had-admin-cipher") || null;
  }

  setCipher(token) {
    Cookies.set("had-admin-cipher", token, { expires: 1 }); // expire 24 hours
  }

  clearCipher() {
    Cookies.remove("had-admin-cipher");
  }
}

const auth = new Auth();
export default auth;
